'use client';

import React from 'react';

import Link from 'components/nextjs-fixes/link';

import { useParams } from 'next/navigation';

import Container from '@repo/ui/container';

import { useTranslation } from 'hooks/use-translation';

import { styles } from '.';

const ErrorLayout = () => {
  const params = useParams();

  const { t } = useTranslation(params?.locale as string);

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <div className={styles.head}>
            <h1 className={styles.title}>Oops.</h1>
            <p className={styles.description}>
              {t('layout.error.description')}
            </p>
          </div>
          <Link href={`/${params?.locale}`}>
            <div className={styles.button.container}>
              <p className={styles.button.label}>{t('layout.error.button')}</p>
            </div>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ErrorLayout;
