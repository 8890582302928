const styles = {
  container: 'py-36 sm:py-56 font-medium',
  content: 'flex flex-col gap-y-2',

  head: '',

  title: 'text-8xl opacity-5 text-center',
  description: 'text-xl text-center mt-4',

  button: {
    container: 'flex items-center justify-center text-center underline',
    label: '',
  },
};

export default styles;
